import { useState, useEffect, useCallback } from 'react'

const isBrowser = typeof window !== 'undefined'

const useUrlState = (key, defaultValue = '') => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (isBrowser) {
      const params = new URLSearchParams(window.location.search)
      setValue(params.get(key) || defaultValue)
    }
  }, [key, defaultValue])

  const onSetValue = useCallback(
    newValue => {
      if (isBrowser) {
        setValue(newValue)
        const url = new URL(window.location)
        url.searchParams.set(key, newValue)
        window.history.pushState({}, '', url)
      }
    },
    [key]
  )

  useEffect(() => {
    const handlePopState = () => {
      if (isBrowser) {
        const params = new URLSearchParams(window.location.search)
        setValue(params.get(key) || defaultValue)
      }
    }

    if (isBrowser) {
      window.addEventListener('popstate', handlePopState)
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('popstate', handlePopState)
      }
    }
  }, [key, defaultValue])

  return [value, onSetValue]
}

export default useUrlState
