import { useStaticQuery, graphql } from 'gatsby'



export const BlogPostRelData = () => {
  const { products, recommended } = useStaticQuery(
    graphql`
      query BlogPostRelDataQuery {
      products: allStrapiProduct(limit: 5, sort: { fields: createdAt, order: DESC }) {
        nodes {
          ...ProductCard
        }
      }
      recommended: allStrapiBlogPost(limit: 7, sort: { fields: publishedAt, order: DESC }) {
        nodes {
          id
          title
          url: urlPath
          heroImage {
            gatsbyImageDataMock
          }
          publishedAt(formatString: "MM/DD/YYYY")
          tag {
            category
          }
        }
      }
    }
    `
  )
  return { products, recommended }
}


export default BlogPostRelData
