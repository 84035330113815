import * as React from 'react'
import PropTypes from 'prop-types'

import BlogRelatedItemsCard from '../components/BlogRelatedItemsCard'

const BlogRelatedItems = ({ products }) => {
  return (
    <aside className="mt-10 xl:mt-0 top-5 order-2 grid w-full grid-cols-1 md:grid-cols-3 lg:grid-rows-[1fr_auto] lg:justify-between lg:gap-4 xl:sticky xl:block xl:h-[700px] xl:max-w-[350px]">
      <h2 className="underline-title mb-6 w-full shrink-0 md:col-span-3">Trending Items</h2>
      {products?.map((product, index) => {
        if (index > 2) return null
        return <BlogRelatedItemsCard key={product.sku} product={product} />
      })}
    </aside>
  )
}
BlogRelatedItems.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      small_image: PropTypes.object.isRequired,
      thumbnail: PropTypes.object.isRequired,
    })
  ),
}

export default BlogRelatedItems
