import React, { useState, useCallback } from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import EZShipBoxSideForm, {
  EZShipBoxBackdrop,
  EZShipBoxScroll,
} from '../components/repairs/EZShipBoxSideForm'
import BrandRepairServices from '../components/repairs/BrandRepairServices'
import CustomerRepairLetters from '../components/repairs/CustomerRepairLetters'
import HaveQuestions from '../components/repairs/HaveQuestions'
import OurExperience from '../components/repairs/OurExperience'
import Section from '../components/cms/Section'
import PropTypes from 'prop-types'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'
import { TableOfContentsHTML } from '../components/TableOfContents'


export const RepairBlogSectionRenderer = ({
  data,
}) => {

  const { page } = data

  const title = [page.brand?.brand, page.model].filter(Boolean).join(' ')
  const [open, setOpen] = useState(false)
  const openForm = useCallback(() => {
    EZShipBoxScroll()
    setOpen(true)
  }, [])
  const toggleForm = useCallback(() => setOpen(o => !o), [])
  const [firstSection, secondSection, ...otherSections] = page.sections

  const breadcrumbs = [{ url: '/repairs', name: 'Repairs' }]
  if (page.brand) breadcrumbs.push({ url: page.brand.urlPath, name: page.brand.brand })
  processGatsbyImageDataMock(page.safeHeroImage)
  return (
    <Layout className={'mx-auto'} breadcrumbs={breadcrumbs} canonical={page.url} title={title}>
      <Seo seo={page.seo} title={title} canonical={page.url} article={page} />
      <header
        className={
          'h-80 2xl:h-72 ' +
          ' group flex flex-row justify-center bg-gradient-to-t from-gray-200 to-transparent to-[20%] duration-300 lg:px-[8.33%] px-5'
        }
      >
        <div
          className={
            'hidden md:flex ' +
            ' relative w-1/2 overflow-hidden max-w-[400px] ' +
            ' *:absolute *:top-[50%] *:-z-10 *:w-full *:-translate-y-[50%] *:grayscale *:duration-500 *:group-hover:grayscale-0'
          }
        >
          {page.safeHeroImage ? (
            <GatsbyImage
              image={getImage(page.safeHeroImage)}
              alt={page.brand + ' Watch Repairs'}
              imgStyle={{ objectFit: 'cover' }}
            />
          ) : (
            <StaticImage
              alt={page.brand + ' Watch Repairs'}
              src={'../images/1600-gns-site-banner-00.png'}
              loading="eager"
              imgStyle={{ objectFit: 'cover' }}
            />
          )}
        </div>
        <div
          className={
            'w-full max-w-[800px] flex flex-col items-start justify-center p-5 leading-none'
          }
        >
          <h1 className="banner-h2-grow font-bold text-black">
            <b className=" block text-base uppercase">
              {page.featuredService} REPAIR & SERVICE <br />
            </b>
            <span className="whitespace-pre-line ">{title}</span>{' '}
          </h1>
          <p className={'py-1 line-clamp-5 leading-relaxed'}>
            Gray & Sons Jewelers, located in Miami Beach, Florida is an independent watch service
            center, repairing {title} watches since 1980.
          </p>
          <button className="gns-button" onClick={toggleForm}>
            QUOTE MY REPAIR
          </button>
        </div>
      </header>

      <section className=" relative flex flex-col lg:flex-row max-w-[1366px] mx-auto">
        <div className="w-full order-2 lg:order-1 px-5">
          {firstSection && <Section section={firstSection} />}
          {page.table_of_contents ? <TableOfContentsHTML sections={page.sections} /> : null}
          <div className="my-12">
            <h2 className="underline-title my-5">{page?.brand?.brand || ''} Repair Services</h2>
            <BrandRepairServices services={page?.brand?.services || null} />
          </div>

          {secondSection && <Section section={secondSection} />}
    
          {/* NOTE: If below the fold has content, display it as FLEX else HIDDEN */}
          {otherSections?.length > 0 && (
            <a
              onClick={openForm}
              href={'#top-banner'}
              className="block border-2  rounded-lg border-red-700 mx-auto max-w-max text-center text-red-700  px-4 py-2 text-xl hover:text-white hover:bg-red-700 hover:-translate-y-2 hover:shadow-md duration-150"
            >
              <h3>
                <b>NEED A WATCH REPAIR?</b>
                <br />
                CLICK HERE TO BEGIN THE PROCESS
              </h3>
            </a>
          )}

          {otherSections.map((section, index) => (
            <Section key={index} section={section} />
          ))}
          <HaveQuestions />
          <OurExperience />
        </div>
        <EZShipBoxSideForm open={open} toggleForm={toggleForm} />
      </section>

      <CustomerRepairLetters brand={page.brand?.brand || title} />
      <EZShipBoxBackdrop open={open} toggleForm={toggleForm} />
    </Layout>
  )
}

RepairBlogSectionRenderer.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      brand: PropTypes.shape({
        brand: PropTypes.string,
        urlPath: PropTypes.string,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
      url: PropTypes.string,
      table_of_contents: PropTypes.bool,
      safeHeroImage: PropTypes.object,
      seo: PropTypes.object,
      model: PropTypes.string,
      featuredService: PropTypes.string,
      sections: PropTypes.arrayOf(Section.propTypes.section),
    }),
  }),
}
