import * as React from 'react'
import { useCallback } from 'react'
import { Link } from 'gatsby'
import TailwindScreens from '../../helpers/tailwind'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from './Slider'
import useMediaQuery from '../../hooks/useMediaQuery'
import { BlogSliderButtonNext, BlogSliderButtonPrev } from './slider/BlogSliderButton'
import PropTypes from 'prop-types'

const SlideItemsPerScreen = () => {
  const isMd = useMediaQuery(TailwindScreens.md)
  const isLg = useMediaQuery(TailwindScreens.lg)
  return [
    useCallback(() => {
      if (isLg) return 3
      if (isMd) return 2
      return 1
    }, [isLg, isMd]),
    'w-full md:w-1/2 lg:w-1/3',
  ]
}

const BlogsSlider = ({ blogs, h2Title, className = '' }) => {
  return (
    <section className={'overflow-x-hidden py-10 ' + className}>
      {h2Title && (
        <h2>
          <Link className="underline-title block" to="/blog/">
            {h2Title}
          </Link>
        </h2>
      )}

      <Slider
        className={'mt-4 md:px-5'}
        childrenClassName={'flex-none snap-start p-2 pb-10'}
        itemsPerScreenFactory={SlideItemsPerScreen}
        buttonPrev={BlogSliderButtonPrev}
        buttonNext={BlogSliderButtonNext}
      >
        {blogs.map((blog, index) => (
          <Link
            to={blog.url}
            key={index}
            className={
              'group bg-white flex h-full flex-col items-center justify-center border-b-8 border-transparent p-4 shadow-lg duration-500 hover:-translate-y-2 hover:border-b-8 hover:border-red-700'
            }
          >
            <div className="flex aspect-square items-center justify-center w-full h-full overflow-hidden ">
              {blog.heroImage ? (
                <GatsbyImage
                  image={getImage(blog.heroImage)}
                  alt={blog.title}
                  className={' w-full h-full'}
                />
              ) : (
                <StaticImage
                  imgStyle={{ objectFit: 'cover' }}
                  className="w-full h-full aspect-square"
                  src="../../images/watch-quote-btn.jpeg"
                  alt={blog.title}
                />
              )}
            </div>
            <div className="grid grid-rows-[1fr_auto_auto] gap-2 pt-3 ">
              <h3 className="h-14 overflow-hidden text-xl font-bold text-black  line-clamp-2">
                {blog.title}
              </h3>
              <div className=" overflow-hidden h-20 line-clamp-6 ">{blog.seo?.metaDescription}</div>
              <p className="h-4 leading-none font-bold text-black group-hover:text-red-700 overflow-hidden">
                Read More <span className="sr-only">About {blog.title}</span>
              </p>
            </div>
          </Link>
        ))}
      </Slider>
    </section>
  )
}

BlogsSlider.propTypes = {
  blogs: PropTypes.array.isRequired,
  h2Title: PropTypes.string,
}

export default BlogsSlider
