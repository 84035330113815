import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { USD } from '../helpers/NumberHelper'
import { uniformUrl } from '../helpers/StringHelpers'
import CategoryImage from '../components/CategoryImage'
import { ProductTypeHelper } from '../helpers/ProductTypeHelper'
import finalPrice from '../helpers/product/finalPrice'

const BlogRelatedItemsCard = ({ product }) => {
  const isSale = product?.special_price === null
  const url = uniformUrl(product.urlPath + '/')
  return (
    <Link
      to={url}
      className="group mx-auto relative mb-10 flex w-full flex-col items-center justify-center rounded-md border-2 border-transparent p-4 shadow-lg duration-300 max-w-[350px] lg:flex-col lg:hover:border-red-700 xl:flex-row"
    >
      <div className=" w-full max-w-[155px] aspect-square xl:mr-2">
        <CategoryImage
          sku={product.sku}
          alt={product.name}
          title={product.title}
          normal={product.small_image}
          productType={product.productType || ProductTypeHelper(product)}
          className="w-full max-w-[155px] aspect-square object-contain"
        />
      </div>
      <div className="flex w-full flex-col xl:text-left text-center lg:w-full h-full justify-end">
        <h2 className="font-bold uppercase  text-black line-clamp-1">
          {product.brand || product.short_name}
        </h2>
        {(product.model || product.short_name) && (
          <h3 className="mb-2 uppercase text-black line-clamp-1">
            {product.model || product.short_name}
          </h3>
        )}
        <div className=" text-sm line-clamp-2">{product.description}</div>
        <p className={(isSale ? 'text-red-700' : '') + ' my-3 text-xl font-bold text-gray-700'}>
          {isSale && <b className="bg-red-700 py-1 px-2 text-sm font-bold text-white">SALE</b>}{' '}
          {USD(finalPrice(product))}
        </p>
      </div>
      <div className="absolute -bottom-5 max-w-max rounded-full border-2 border-red-700 bg-red-700 px-3 py-1 text-sm font-bold text-white duration-300 ease-in  lg:-bottom-8 lg:opacity-0 lg:group-hover:-bottom-5 lg:group-hover:opacity-100">
        SHOP NOW
      </div>
    </Link>
  )
}

// create prop types validation for ProductSliderCard
BlogRelatedItemsCard.propTypes = {
  product: PropTypes.object.isRequired,
}

export default BlogRelatedItemsCard
